.title {
  display: flex;
  justify-content: space-between;
  .text {
    line-height: 32px;
    font-size: 24px;
    font-weight: 600;
  }

}

.content {
  padding: 0 20px;
}

.action {
}