.client {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  .select {
    flex: 1 1;
  }
  .minus {
    line-height: 40px;
    font-size: 18px;
  }
}

.icon {
  font-size: 32px;
  color: var(--color-green);
}

.modal {
  width: 624px !important;
}
.message {
  font-size: 12px;
  background-color: var(--color-background);
  padding: 16px;
  border-radius: 8px;
}