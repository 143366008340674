.login {
    .content {
        max-width: 500px;
        margin: auto;
        padding: 24px 20px;
    }
}

.layout {
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    //margin-bottom: 80px;
}

.content {
    flex: 1 1;
}

.footer {
    //width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .bold {font-weight: 500}
}