.control {
  height: 32px;
  background-color: var(--color-dark);
}

.range {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.wrapper {
  background-color: var(--color-dark);
  color: var(--color-white);
  padding-bottom: 64px;
}

.title {
  font-size: 24px;
  .name {
    font-weight: 700;
  }
  .group {
    font-weight: 300;
  }
}

.bold {
  font-weight: 500;
}

.balance {

  position: relative;
  top: -32px;
  background-color: var(--color-white);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  .value {
    font-size: 32px;
    font-weight: 500;
  }
  .phone {
    color: var(--color-gray);
    margin-bottom: 8px;
  }
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter {
  background-color: var(--color-background);
  border: none;
  border-radius: 16px;
}

.date {
  color: var(--color-gray);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  gap: 8px;
  color: var(--color-text);
  .time {
    width: 44px;
  }
  .comment {
    flex: 1;
    //width: calc(100% - 44px - 16px - 100px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }
  .sum {
    color: var(--color-text);
    text-align: right;
    &.plus {
      color: var(--color-green);
    }
  }
}

.alert {
  margin-bottom: 32px;
}

.orders {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  .row div {
    color: var(--color-gray) !important;
  }
}