.client {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  .select {
    flex: 1 1;
  }
  .minus {
    line-height: 40px;
    font-size: 18px;
  }
}