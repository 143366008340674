.control {
  height: 32px;
  background-color: var(--color-dark);
}

.wrapper {
  background-color: var(--color-dark);
  color: var(--color-white);
  padding-bottom: 32px;
}

.title {
  font-size: 24px;
  .name {
    font-weight: 400;
  }
}

.page {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}