.filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;

  .left {
    flex: 1 1;
  }
  .right {
    flex: 1 1;
    text-align: right;
  }

}

.expanded {
  //display: flex;
  //gap: 40px;
  .line {
    display: flex;
    gap: 40px;
  }
}

.hided {
  opacity: 0.3;
}

.icon {
  font-size: 32px;
  color: var(--color-green);
}

.modal {
  width: 624px !important;
}
.message {
  font-size: 12px;
  background-color: var(--color-background);
  padding: 16px;
  border-radius: 8px;
}

.align {
  text-align: center;
}

.warning {
  color: var(--color-gray);
}
.success {
  color: var(--color-green);
}
.error {
  color: var(--color-error);
}