.wrapper {
  background-color: var(--color-dark);
  color: var(--color-white);
  padding: 16px 0;

}
.header {
  display: flex;
  justify-content: space-between;

  &.admin {
    padding: 0 20px;
  }

  //padding: 0 15px 0 15px;
  //margin: 16px auto;
  .logo {
    width: 107px;
    height: 32px;
  }
}

.buttonsRow {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  button {
    width: calc((100% - 8px) / 2);
  }
}

.buttonsLine {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  button {
    width: 100%;
  }
}

.link {
  color: var(--color-white);
  &.selected {
    font-weight: bold;
  }
}