@font-face {
  font-family: "SB Sans";
  src: url('fonts/SBSansDisplay-Thin.woff2') format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SB Sans";
  src: url('fonts/SBSansDisplay-Light.woff2') format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SB Sans";
  src: url('fonts/SBSansDisplay-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SB Sans";
  src: url('fonts/SBSansDisplay-SemiBold.woff2') format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SB Sans";
  src: url('fonts/SBSansDisplay-Bold.woff2') format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

$brand: #00A6CE;
$action: #5CD6CE;
$border: #E2E5E9;
$text: #29393D;
$white: #FFFFFF;

:root {
  --color-white: #FFFFFF;
  --color-text: #333333;
  --color-dark: #143361;
  --color-action: #37779B;
  --color-gray: #868786;

  --color-border: #D9D9D9;
  --color-background: #F0F0F0;
  --color-green: #47D780;
  --color-error: #FF0000;
}

// App
body, html {
  background: #fff;
  color: var(--color-text);
  font-family: 'SB Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  margin: 0;
  height: 100%;
}

a {
  color: var(--color-action);

  &:hover {
    color: var(--color-action);
  }
}

// content
.content {
  max-width: 700px;
  padding: 0 20px;
  margin: auto;
}

.block {
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 500;
}

.slick-dots {
  justify-content: left !important;
  padding: 0 20px !important;
  max-width: 700px !important;
  margin: auto !important;
}

.admin-page {
  padding: 40px 20px;
}